import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/Common/Footer'
import BlogCard from '../components/BlogContent/BlogCard'
import Pagination from '../components/Common/Pagination'

const ComponentName = ({ data }) => {
  const title = 'Blog — Brewedlogic Inc.'

  return (
    <Layout title={title}>
      <Navbar />

      <PageBanner
        pageTitle="Blog"
        homePageText="Home"
        homePageUrl="/blog"
        activePageText="Blog"
      />

      <section className="blog-area ptb-100">
        <div className="container">
          <div className="row">
            <BlogCard blogs={data.blog.nodes} className="col-lg-6" />
          </div>
        </div>

        <Pagination pageInfo={data.blog.pageInfo} />

      </section>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetBlogByPage($currentPage: Int) {
    blog: allStrapiBlog(
      skip: $currentPage
      limit: 10
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        title
        slug
        content
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        categories {
          category
          slug
        }
        date: date(formatString: "MMMM D, YYYY")
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        pageCount
        currentPage
      }
    }
  }
`

export default ComponentName
